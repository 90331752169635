<template>
    <div class="account-pages my-5 pt-sm-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card overflow-hidden">
                            <div class="bg-primary bg-soft">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="text-primary p-4">
                                            <h5 class="text-primary">Free Register</h5>
                                            <p>Get your free Skote account now.</p>
                                        </div>
                                    </div>
                                    <div class="col-5 align-self-end">
                                        <img src="frontend/assets/images/profile-img.png" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-0"> 
                                <div>
                                    <a href="index.html">
                                        <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-light">
                                                <img src="frontend/assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-2">
                                    <form  @submit.prevent="register()" class="needs-validation" novalidate action="https://themesbrand.com/skote-django/layouts/index.html">
            
                                        <div class="mb-3">
                                            <label for="useremail" class="form-label">Email</label>
                                            <input   v-model="registeration.email" type="email" class="form-control" id="useremail" placeholder="Enter email" required>  
                                            <div class="invalid-feedback">
                                                Please Enter Email
                                            </div>      
                                        </div>
                
                                        <div class="mb-3">
                                            <label for="username" class="form-label">Nom</label>
                                            <input  v-model="registeration.name" type="text" class="form-control" id="username" placeholder="Enter username" required>
                                            <div class="invalid-feedback">
                                                Please Enter Username
                                            </div>  
                                        </div>
                
                                        <div class="mb-3">
                                            <label for="userpassword" class="form-label">Mot de passe</label>
                                            <input type="password" v-model="registeration.password" class="form-control" id="userpassword" placeholder="Enter password" required>
                                            <div class="invalid-feedback">
                                                Please Enter Password
                                            </div>       
                                        </div>
                                          <div class="mb-3">
                                            <label for="userpassword" class="form-label">Mot de passe confirmée</label>
                                            <input type="password" v-model="registeration.c_password" class="form-control" id="c_userpassword" placeholder="Enter password" required>
                                            <div class="invalid-feedback">
                                                Please Enter Password
                                            </div>       
                                        </div>
                    
                                        <div class="mt-4 d-grid">
                                            <button class="btn btn-primary waves-effect waves-light" type="submit">Register</button>
                                        </div>

                                        <div class="mt-4 text-center">
                                            <h5 class="font-size-14 mb-3">Sign up using</h5>
            
                                            <ul class="list-inline">
                                                <li class="list-inline-item">
                                                    <a href="javascript::void()" class="social-list-item bg-primary text-white border-primary">
                                                        <i class="mdi mdi-facebook"></i>
                                                    </a>
                                                </li>
                                                <li class="list-inline-item">
                                                    <a href="javascript::void()" class="social-list-item bg-info text-white border-info">
                                                        <i class="mdi mdi-twitter"></i>
                                                    </a>
                                                </li>
                                                <li class="list-inline-item">
                                                    <a href="javascript::void()" class="social-list-item bg-danger text-white border-danger">
                                                        <i class="mdi mdi-google"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                
                                        <div class="mt-4 text-center">
                                            <p class="mb-0">By registering you agree to the Skote <a href="#" class="text-primary">Terms of Use</a></p>
                                        </div>
                                    </form>
                                </div>
            
                            </div>
                        </div>
                        <div class="mt-5 text-center">
                            
                            <div>
                                <p>Already have an account ? <a href="auth-login.html" class="fw-medium text-primary"> Login</a> </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
</template>
<script>
    import axios from 'axios'
    export default{
        name:'Register',
        data () {
            return {
                registeration: { email: '',name:'', password: '',c_password:'',role:'boutique' },
                user: {},
                logged:window.localStorage.getItem('')
            }
       },
       methods: {
        /**
         * Attempts to authenticate the user
         *
         * @return {mixed}
         */
            register () {
                let credentials = this.registeration
                console.log(credentials);
                axios.post('http://localhost:8000/api/register', credentials).then((response) => {
                    window.localStorage.setItem('token', response.data.token)
                    window.localStorage.setItem('isLogged', 'logged')
                    window.localStorage.setItem('auth-user', JSON.stringify(response.data.user))
                    window.localStorage.setItem('name', response.data.user.name)

                    this.$router.push("/home");
                }).catch((errors) => {
                })
            }
        }



    }
</script>
<style>

</style>