<template>
    <div id="flowbaseSlider" class="form-wrapper w-form">
     <form>
       <div class="slider w-slider">
           <div class="mask w-slider-mask">
               <div class="slide w-slide" id="stap1" style="display: block">
                   <div class="slide-wrapper">
                       <div class="slide-content">
                           <div class="form-content">
                               <div class="slide-detail">
                               <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon02(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div class="active-tab">
                                   <div class="slide-tab-current"><img src="/frontend/images/Icon03(White).svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="step-detail">
                                       <div class="step-count">Step 3/4</div>
                                       <div class="step-title">Les modeles</div>
                                   </div>
                               </div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon04.svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                           </div>
                               <div class="form-wrap">
                                   <div class="title-wrap">
                                       <h2 class="form-heading">What services do you need?</h2>
                                       <p class="paragraph-header">Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                                   </div>
                                    <h2 class="form-heading">Panne en commun </h2>
                                   <div class="w-layout-grid grid-three-column">
                                    <label class="w-checkbox checkbox-field"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"><input type="checkbox" id="Design" name="Design" data-name="Design" /><img :src="`http://admin.phonixphone.fr/materiels/Image/${materiel.image}`" loading="lazy" alt="" class="form-icon" style="
                                          width: 97px; height: 43px;position:relative;"/><span for="Design" class="checkbox-label w-form-label">{{materiel.name}} </span> <router-link :to="{name: 'listePannes', params: {id:materiel.id}}" ><a  class=" button-config  w-button">configurer</a></router-link></div></label>
                                  </div>
                                   <h2 class="form-heading">Panne par modele </h2>
                                   <div class="w-layout-grid grid-three-column"  >
                                    <label v-for="modele in modeles" class="w-checkbox checkbox-field" :key="modele.id"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"><input type="checkbox" id="Design" name="Design" data-name="Design" /><img :src="`http://admin.phonixphone.fr/modeles/Image/${modele.image}`" loading="lazy" alt="" class="form-icon" style="
                                          width: 97px; height: 43px;position:relative;"/><span for="Design" class="checkbox-label w-form-label">{{modele.name}} </span> <router-link :to="{name: 'listePanne', params: {id:modele.id}}" ><a  class=" button-config  w-button">configurer</a></router-link></div></label>
                                    </div>

                               </div>
                               <div class="button-base"><a href="#stap2" class="next-button-slide w-button">Next Step</a></div>
                           </div>
                           
                       </div>
                   </div>
               </div>
               <div class="slide w-slide" id="stap2">
                   <div class="slide-wrapper">
                       <div class="slide-content">
                           <div class="form-content">
                               <div class="slide-detail">
                                   <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="line-break"></div>
                                   <div class="active-tab">
                                       <div class="slide-tab-current"><img src="/frontend/images/Icon02(White).svg" alt="" class="slider-tab-icon" /></div>
                                       <div class="step-detail">
                                           <div class="step-count">Step 2/4</div>
                                           <div class="step-title">Contact Details</div>
                                       </div>
                                   </div>
                                   <div class="line-break"></div>
                                   <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon03.svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="line-break"></div>
                                   <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon04.svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="line-break"></div>
                                   <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon05.svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="line-break"></div>
                                   <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon06.svg" alt="" class="slider-tab-icon" /></div>
                               </div>
                               <div class="form-wrap">
                                   <div class="title-wrap">
                                       <h2 class="form-heading">We can’t wait to meet you.</h2>
                                       <p class="paragraph-header">Please fill in the details below so that we can get in contact with you.</p>
                                   </div>
                                   <div class="w-layout-grid grid-single-column">
                                       <div class="field-wrap-large"><label for="First-Name" class="field-label">Please enter your name</label><input type="text" class="text-field w-input" maxlength="256" name="First-Name" data-name="First Name" placeholder="First Name" id="First-Name" /></div>
                                       <div
                                           class="field-wrap-large"><label for="Phone-Number" class="field-label">Please enter a phone number</label><input type="text" class="text-field w-input" maxlength="256" name="Phone-Number" data-name="Phone Number" placeholder="Phone Number" id="Phone-Number"
                                           /></div>
                                   <div class="field-wrap-large"><label for="Website" class="field-label">Please enter a link to your website (optional)</label><input type="text" class="text-field w-input" maxlength="256" name="Website" data-name="Website" placeholder="Website" id="Website"
                                       /></div>
                               </div>
                           </div>
                           <div class="button-base">
                               <a href="#stap1" class="back-button-slide w-inline-block">
                                   <div>Back</div>
                               </a><a href="#stap3" class="next-button-slide w-button">Next Step</a></div>
                       </div>
                   </div>
               </div>
           </div>
             <div class="slide w-slide" id="stap3">
                 <div class="slide-wrapper">
                   <div class="slide-content">
                       <div class="form-content">
                           <div class="slide-detail">
                               <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon02(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div class="active-tab">
                                   <div class="slide-tab-current"><img src="/frontend/images/Icon03(White).svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="step-detail">
                                       <div class="step-count">Step 3/6</div>
                                       <div class="step-title">Written Content</div>
                                   </div>
                               </div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon04.svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon05.svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon06.svg" alt="" class="slider-tab-icon" /></div>
                           </div>
                           <div class="form-wrap">
                               <div class="title-wrap">
                                   <h2 class="form-heading">Do you have written content available?</h2>
                                   <p class="paragraph-header">Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                               </div>
                               <div class="w-layout-grid grid-two-column"><label class="w-checkbox checkbox-field"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="Content Yes" name="Content-Yes" data-name="Content Yes"/><img src="/frontend/images/Yes.svg" alt="" class="form-icon"/><span for="Content-Yes" class="checkbox-label w-form-label">Yes</span></label>
                                   <label class="w-checkbox checkbox-field">
                                       <div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="Content No" name="Content-No" data-name="Content No" /><img src="/frontend/images/No.svg" alt="" class="form-icon" /><span for="Content-No" class="checkbox-label w-form-label">No</span></label>
                               </div>
                           </div>
                           <div class="button-base">
                               <a href="#stap2" class="back-button-slide w-inline-block">
                                   <div>Back</div>
                               </a><a href="#stap4" class="next-button-slide w-button">Next Step</a></div>
                       </div>
                   </div>
               </div>
           </div>
             <div class="slide w-slide" id="stap4">
                 <div class="slide-wrapper">
                     <div class="slide-content">
                         <div class="form-content">
                             <div class="slide-detail">
                                 <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon02(Filled).svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs active"><img src="/frontend/images/Icon03(Filled).svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div class="active-tab">
                                     <div class="slide-tab-current"><img src="/frontend/images/Icon04(White).svg" alt="" class="slider-tab-icon" /></div>
                                     <div class="step-detail">
                                         <div class="step-count">Step 4/6</div>
                                         <div class="step-title">Illustrations</div>
                                     </div>
                                 </div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon05.svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="/frontend/images/Icon06.svg" alt="" class="slider-tab-icon" /></div>
                             </div>
                             <div class="form-wrap">
                                 <div class="title-wrap">
                                     <h2 class="form-heading">Do you have Illustations or Photography?</h2>
                                     <p class="paragraph-header">Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                                 </div>
                                 <div class="w-layout-grid grid-two-column"><label class="w-checkbox checkbox-field"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox yes"></div><input type="checkbox" id="Illustration Yes" name="Illustration-Yes" data-name="Illustration Yes" /><img src="/frontend/images/Yes.svg" alt="" class="form-icon"/><span for="Illustration-Yes" class="checkbox-label w-form-label">Yes</span></label>
                                     <label
                                         class="w-checkbox checkbox-field">
                                         <div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="Illustration No" name="Illustration-No" data-name="Illustration No" /><img src="/frontend/images/No.svg"
                                            alt="" class="form-icon" /><span for="Illustration-No" class="checkbox-label w-form-label">No</span></label>
                                 </div>
                             </div>
                             <div class="button-base">
                                 <a href="#stap3" class="back-button-slide w-inline-block">
                                     <div>Back</div>
                                 </a><a href="#stap5" class="next-button-slide w-button">Next Step</a></div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         <div class="previous-placeholder w-slider-arrow-left"><img src="/frontend/images/arrow.svg" alt="" class="arrow" />
             <div class="previous-button">Previous</div>
         </div>
         <div class="next-placeholder w-slider-arrow-right">
             <div class="next-button">Next step</div>
         </div>
       </div>
     </form>
   </div>
   </template>
   
   <script >
    import axios from 'axios'
  
      export default{
      data(){
          return{
              modeles:[],
              materiel:{}
          }
      },
      created() {
             axios.get(`http://localhost:8000/api/materiel/modeles/${this.$route.params.id}/${this.$route.params.materiel_id}`)
              .then(response => {
                  this.modeles = response.data.modeles;
                  this.materiel = response.data.materiel;
                  console.log(response.data.materiel);
  
              });
        
      }
      }
   </script>
   
   <!-- Add "scoped" attribute to limit CSS to this component only -->
   <style scoped lang="scss">
   
   </style>
   