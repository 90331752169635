import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import Register from '../views/Register.vue';
import Login from '../views/Login.vue';
import Menu from '../views/dashboard/Menu.vue';
import Materiels from '../views/dashboard/materiels/Liste.vue';
import CreateMateriels from '../views/dashboard/materiels/create.vue';

import Marques from '../views/dashboard/marques/Liste.vue';
import Modeles from '../views/dashboard/modeles/Liste.vue';
import Pannes from '../views/dashboard/pannes/Liste.vue';
import Boutiques from '../views/dashboard/boutiques/Liste.vue';

import Update from '../views/dashboard/materiels/Update.vue';
import UpdateModele from '../views/dashboard/modeles/Update.vue';
import UpdatePanne from '../views/dashboard/pannes/Update.vue';
import UpdateBoutique from '../views/dashboard/boutiques/Update.vue';



import CreateMarque from '../views/dashboard/marques/Create.vue';
import ListeMarque from '../views/dashboard/marques/Marque.vue';
import ListeModele from '../views/dashboard/modeles/Modele.vue';
import ListePanne from '../views/dashboard/pannes/Panne.vue';
import ListePannes from '../views/dashboard/pannes/Pannes.vue';



import CreateModele from '../views/dashboard/modeles/Create.vue';
import CreatePanne from '../views/dashboard/pannes/Create.vue';
import StorePanne from '../views/dashboard/pannes/Store.vue';

import CreateBoutique from '../views/dashboard/boutiques/Create.vue';



import Step from '../views/clients/Marque.vue';




const routes: Array<RouteRecordRaw> = [
  {
    path: '/step',
    name: 'step',
    component: Step
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'menu',
    component: Menu,
 
  },
  {
    path: '/materiels',
    name: 'materiels',
    component: Materiels,
  },
  {
    path: '/materiel/create',
    name: 'create-materiels',
    component: CreateMateriels,
  },
  {
    path: '/materiel/edit/:id',
    name: 'editMateriel',
    component: Update,
  },
  {
    path: '/marques',
    name: 'marques',
    component: Marques,
  },
  {
    path: '/marque/:id',
    name: 'listeMarques',
    component: ListeMarque,
  },
  {
    path: '/marque/create',
    name: 'create-marques',
    component: CreateMarque,
  },
  {
    path: '/modeles',
    name: 'modeles',
    component: Modeles,
  },
  {
    path: '/modele/create',
    name: 'create-modele',
    component: CreateModele,
  },
  {
    path: '/modele/edit/:id',
    name: 'editModele',
    component: UpdateModele,
  },
  {
    path: '/modele/:id/:materiel_id',
    name: 'listeModeles',
    component: ListeModele,
  },
  {
    path: '/pannes',
    name: 'pannes',
    component: Pannes,
  },
  {
    path: '/panne/create',
    name: 'create-panne',
    component: CreatePanne,
  },
  {
    path: '/pannes/store',
    name: 'store-panne',
    component: StorePanne,
  },
  {
    path: '/panne/edit/:id',
    name: 'editPanne',
    component: UpdatePanne,
  },
  
  {
    path: '/panne/:id',
    name: 'listePanne',
    component: ListePanne,
  },
  {
    path: '/pannes/:id',
    name: 'listePannes',
    component: ListePannes,
  },
  {
    path: '/boutiques',
    name: 'boutiques',
    component: Boutiques,
  },
  {
    path: '/boutiques/create',
    name: 'create-boutique',
    component: CreateBoutique,
  },
  {
    path: '/boutiques/edit/:id',
    name: 'editBoutique',
    component: UpdateBoutique,
   
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    beforeEnter:(to,from,next)=>{
      if(localStorage.getItem('token')) {
        router.push({ name: 'editBoutique' });
    }
  
    router.push({ name: 'login' });

    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
