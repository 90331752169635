<template>
    <div class="vertical-menu">
   
   <div data-simplebar class="h-100">
   
       <!--- Sidemenu -->
       <div id="sidebar-menu">
           <!-- Left Menu Start -->
           <ul class="metismenu list-unstyled" id="side-menu">
            <li class="menu-title" key="t-menu">Menu</li>

            <li>
                <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="bx bx-home-circle"></i>
                    <span key="t-dashboards">Dashboards</span>
                </a>
            </li>
            <li>
                <a href="/boutiques" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Boutiques</span>
                </a>
            </li>
            <li>
                <a href="/materiels" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Materiels</span>
                </a>
            </li>
            <li>
                <a href="/marques" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Marques</span>
                </a>
            </li>
            <li>
                <a href="/modeles" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Modeles</span>
                </a>
            </li>
            <li>
                <a href="/pannes" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Pannes</span>
                </a>
            </li>
        </ul>
       </div>
       <!-- Sidebar -->
   </div>
</div>
<!-- Left Sidebar End -->   
   
   <!-- ============================================================== -->
   <!-- Start right Content here -->
   <!-- ============================================================== -->
<div class="main-content">
   
   <div class="page-content">
    <div class="container-fluid">
        <div class="col-12">
            <div class="card">
                <form @submit.prevent="profileUpload" method="POST" enctype="multipart/form-data">
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label" >Nom du materiel</label>
                        <select class="form-control" v-model="materielSelected" name="mat" >
                            <option  v-for="materiel in materiels" :value="materiel.name">{{materiel.name}}</option>
                        </select>     
                    </div>
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Nom du panne</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Entrez titre de matériel" v-model="name">
                    </div>
                   
                    <label for="formrow-firstname-input" class="form-label">Image du panne</label>
                    <div class="card-body">
                        <div class="dropzone">
                                <div class="fallback">
                                    <input  type="file" multiple="multiple"  @change="imageSelected">
                                </div>
                                <div class="dz-message needsclick">
                                    <div class="mb-3">
                                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                    </div>
                                    <div v-if="imagepreview" class="mt-3">
                                        <img :src="imagepreview" class="figure-img img-fluid rounded"  style="max-height:100px;">
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary w-md">Submit</button>
                </form>
            </div>
        </div> <!-- end col -->
</div>
 </div>
   <!-- End Page-content -->
   
   
   <footer class="footer">
       <div class="container-fluid">
           <div class="row">
              
               <div class="col-sm-6">
                   <div class="text-sm-end d-none d-sm-block">
                       Design & Develop by Themesbrand
                   </div>
               </div>
           </div>
       </div>
   </footer>
   </div>
   <!-- end main content-->
   
   <!-- END layout-wrapper -->
   
   <!-- Right Sidebar -->
   <div class="right-bar">
   <div data-simplebar class="h-100">
   <div class="rightbar-title d-flex align-items-center px-3 py-4">
   
       <h5 class="m-0 me-2">Settings</h5>
   
       <a href="javascript:void(0);" class="right-bar-toggle ms-auto">
           <i class="mdi mdi-close noti-icon"></i>
       </a>
   </div>
   
   <!-- Settings -->
   <hr class="mt-0" />
   <h6 class="text-center mb-0">Choose Layouts</h6>
   
   <div class="p-4">
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-1.jpg" class="img-thumbnail" alt="layout images">
       </div>
   
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="light-mode-switch" checked>
           <label class="form-check-label" for="light-mode-switch">Light Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-2.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="dark-mode-switch">
           <label class="form-check-label" for="dark-mode-switch">Dark Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-3.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch">
           <label class="form-check-label" for="rtl-mode-switch">RTL Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-4.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-5">
           <input class="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch">
           <label class="form-check-label" for="dark-rtl-mode-switch">Dark RTL Mode</label>
       </div>
   
   
   </div>
   
   </div> <!-- end slimscroll-menu-->
   </div>
   </template>
   <script>
   import axios from 'axios'
   import defaultUrl from '../../../global.js'

 export default{
    data(){
        return{
            image: null,
            imagepreview: null,
            name:null,
            materiels:[],
            materielSelected:null
        }
    },
    created() {
        let token =  window.localStorage.getItem('token');
        axios.get(defaultUrl+'api/materiels', {
            headers: {
              Authorization: "Bearer" + token,
              "x-access-token": token
            }
          })
            .then(response => {
                this.materiels = response.data.materiels;

            });
    },
    methods:{
        imageSelected(e){
            this.image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(this.image);
            reader.onload = e => {
            this.imagepreview = e.target.result;
               };
        },
        profileUpload(){
            let data = new FormData;
            data.append('image', this.image);
            data.append('name', this.name);
            data.append('materielSelected', this.materielSelected);
            console.log('materiel selected',this.materielSelected);
            axios.post(defaultUrl+'api/panne', data)
            .then(()=>{
                this.$router.push({ name: 'pannes' });

            }).catch(()=>{
            })
        }
    }
}
   
    
        

   </script>
   <style>
   
   </style>