<template>
  <div id="flowbaseSlider" class="form-wrapper w-form">
   <form>
     <div class="slider w-slider">
         <div class="mask w-slider-mask">
             <div class="slide w-slide" id="stap1" style="display: block">
                 <div class="slide-wrapper">
                     <div class="slide-content">
                         <div class="form-content">
                             <div class="slide-detail">
                                 <div class="active-tab">
                                     <div class="slide-tab-current"><img src="frontend/images/Icon01.svg" alt="" /></div>
                                     <div class="step-detail">
                                         <div class="step-count">Step 1/4</div>
                                         <div class="step-title">Type de materiels</div>
                                     </div>
                                 </div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon02.svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon03.svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon04.svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                             </div>
                             <div class="form-wrap">
                                 <div class="title-wrap">
                                     <h2 class="form-heading">What services do you need?</h2>
                                     <p class="paragraph-header">Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                                 </div>
                                 <div  class="w-layout-grid grid-three-column"  >

                                     <label v-for="materiel in materiels" class="w-checkbox checkbox-field" :key="materiel.id"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox" ><input type="checkbox" id="Design" name="Design" data-name="Design" /><img :src="`http://admin.phonixphone.fr/materiels/Image/${materiel.image}`" loading="lazy" alt="" class="form-icon" style="
                                    width: 97px; height: 43px;position:relative;"/><span for="Design" class="checkbox-label w-form-label">{{materiel.name}}</span> <router-link :to="{name: 'listeMarques', params: { id: materiel.id }}" ><a  class=" button-config  w-button">configurer</a></router-link></div></label>
                                    
                                </div>
                                
                             </div>
                         </div>
                     </div>

                 </div>
             </div>
             <div class="slide w-slide" id="stap2">
                 <div class="slide-wrapper">
                     <div class="slide-content">
                         <div class="form-content">
                             <div class="slide-detail">
                                 <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div class="active-tab">
                                     <div class="slide-tab-current"><img src="frontend/images/Icon02(White).svg" alt="" class="slider-tab-icon" /></div>
                                     <div class="step-detail">
                                         <div class="step-count">Step 2/6</div>
                                         <div class="step-title">Contact Details</div>
                                     </div>
                                 </div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon03.svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon04.svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon05.svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="line-break"></div>
                                 <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon06.svg" alt="" class="slider-tab-icon" /></div>
                             </div>
                             <div class="form-wrap">
                                 <div class="title-wrap">
                                     <h2 class="form-heading">We can’t wait to meet you.</h2>
                                     <p class="paragraph-header">Please fill in the details below so that we can get in contact with you.</p>
                                 </div>
                                 <div class="w-layout-grid grid-single-column">
                                     <div class="field-wrap-large"><label for="First-Name" class="field-label">Please enter your name</label><input type="text" class="text-field w-input" maxlength="256" name="First-Name" data-name="First Name" placeholder="First Name" id="First-Name" /></div>
                                     <div
                                         class="field-wrap-large"><label for="Phone-Number" class="field-label">Please enter a phone number</label><input type="text" class="text-field w-input" maxlength="256" name="Phone-Number" data-name="Phone Number" placeholder="Phone Number" id="Phone-Number"
                                         /></div>
                                 <div class="field-wrap-large"><label for="Website" class="field-label">Please enter a link to your website (optional)</label><input type="text" class="text-field w-input" maxlength="256" name="Website" data-name="Website" placeholder="Website" id="Website"
                                     /></div>
                             </div>
                         </div>
                         <div class="button-base">
                             <a href="#stap1" class="back-button-slide w-inline-block">
                                 <div>Back</div>
                             </a><a href="#stap3" class="next-button-slide w-button">Next Step</a></div>
                     </div>
                 </div>
             </div>
         </div>
           <div class="slide w-slide" id="stap3">
               <div class="slide-wrapper">
                 <div class="slide-content">
                     <div class="form-content">
                         <div class="slide-detail">
                             <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                             <div class="line-break"></div>
                             <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon02(Filled).svg" alt="" class="slider-tab-icon" /></div>
                             <div class="line-break"></div>
                             <div class="active-tab">
                                 <div class="slide-tab-current"><img src="frontend/images/Icon03(White).svg" alt="" class="slider-tab-icon" /></div>
                                 <div class="step-detail">
                                     <div class="step-count">Step 3/6</div>
                                     <div class="step-title">Written Content</div>
                                 </div>
                             </div>
                             <div class="line-break"></div>
                             <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon04.svg" alt="" class="slider-tab-icon" /></div>
                             <div class="line-break"></div>
                             <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon05.svg" alt="" class="slider-tab-icon" /></div>
                             <div class="line-break"></div>
                             <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon06.svg" alt="" class="slider-tab-icon" /></div>
                         </div>
                         <div class="form-wrap">
                             <div class="title-wrap">
                                 <h2 class="form-heading">Do you have written content available?</h2>
                                 <p class="paragraph-header">Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                             </div>
                             <div class="w-layout-grid grid-two-column"><label class="w-checkbox checkbox-field"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="Content Yes" name="Content-Yes" data-name="Content Yes"/><img src="frontend/images/Yes.svg" alt="" class="form-icon"/><span for="Content-Yes" class="checkbox-label w-form-label">Yes</span></label>
                                 <label class="w-checkbox checkbox-field">
                                     <div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="Content No" name="Content-No" data-name="Content No" /><img src="frontend/images/No.svg" alt="" class="form-icon" /><span for="Content-No" class="checkbox-label w-form-label">No</span></label>
                             </div>
                         </div>
                         <div class="button-base">
                             <a href="#stap2" class="back-button-slide w-inline-block">
                                 <div>Back</div>
                             </a><a href="#stap4" class="next-button-slide w-button">Next Step</a></div>
                     </div>
                 </div>
             </div>
         </div>
           <div class="slide w-slide" id="stap4">
               <div class="slide-wrapper">
                   <div class="slide-content">
                       <div class="form-content">
                           <div class="slide-detail">
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon02(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon03(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div class="active-tab">
                                   <div class="slide-tab-current"><img src="frontend/images/Icon04(White).svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="step-detail">
                                       <div class="step-count">Step 4/6</div>
                                       <div class="step-title">Illustrations</div>
                                   </div>
                               </div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon05.svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon06.svg" alt="" class="slider-tab-icon" /></div>
                           </div>
                           <div class="form-wrap">
                               <div class="title-wrap">
                                   <h2 class="form-heading">Do you have Illustations or Photography?</h2>
                                   <p class="paragraph-header">Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                               </div>
                               <div class="w-layout-grid grid-two-column"><label class="w-checkbox checkbox-field"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox yes"></div><input type="checkbox" id="Illustration Yes" name="Illustration-Yes" data-name="Illustration Yes" /><img src="frontend/images/Yes.svg" alt="" class="form-icon"/><span for="Illustration-Yes" class="checkbox-label w-form-label">Yes</span></label>
                                   <label
                                       class="w-checkbox checkbox-field">
                                       <div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="Illustration No" name="Illustration-No" data-name="Illustration No" /><img src="frontend/images/No.svg"
                                          alt="" class="form-icon" /><span for="Illustration-No" class="checkbox-label w-form-label">No</span></label>
                               </div>
                           </div>
                           <div class="button-base">
                               <a href="#stap3" class="back-button-slide w-inline-block">
                                   <div>Back</div>
                               </a><a href="#stap5" class="next-button-slide w-button">Next Step</a></div>
                       </div>
                   </div>
               </div>
           </div>
           <div class="slide w-slide" id="stap5">
               <div class="slide-wrapper">
                   <div class="slide-content">
                       <div class="form-content">
                           <div class="slide-detail">
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon02(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon03(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon04(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div class="active-tab">
                                   <div class="slide-tab-current"><img src="frontend/images/Icon05(White).svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="step-detail">
                                       <div class="step-count">Step 5/6</div>
                                       <div class="step-title">Project Bracket</div>
                                   </div>
                               </div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs"><img src="frontend/images/Icon06.svg" alt="" class="slider-tab-icon" /></div>
                           </div>
                           <div class="form-wrap">
                               <div class="title-wrap">
                                   <h2 class="form-heading">What project bracket are you in?</h2>
                                   <p class="paragraph-header">Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
                               </div>
                               <div class="w-layout-grid grid-three-column"><label class="w-checkbox checkbox-field"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="5000+" name="5000" data-name="5000+" /><img src="frontend/images/PriceTier01.svg" alt="" class="form-icon"/><span for="5000" class="checkbox-label w-form-label">$5,000+</span></label>
                                   <label
                                       class="w-checkbox checkbox-field">
                                       <div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="10000+" name="10000" data-name="10000+"/><img src="frontend/images/PriceTier02.svg"
                                           loading="lazy" alt="" class="form-icon" /><span for="10000" class="checkbox-label w-form-label">$10,000+</span></label><label class="w-checkbox checkbox-field"><div class="w-checkbox-input w-checkbox-input--inputType-custom large-checkbox"></div><input type="checkbox" id="20000+" name="20000" data-name="20000+"/><img src="frontend/images/PriceTier03.svg" alt="" class="form-icon"/><span for="20000" class="checkbox-label w-form-label">$20,000+</span></label></div>
                           </div>
                           <div class="button-base">
                               <a href="#stap4" class="back-button-slide w-inline-block">
                                   <div>Back</div>
                               </a><a href="#stap6" class="next-button-slide w-button">Next Step</a></div>
                       </div>
                   </div>
               </div>
           </div>
           <div class="slide w-slide" id="stap6">
               <div class="slide-wrapper">
                   <div class="slide-content">
                       <div class="form-content">
                           <div class="slide-detail">
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon01(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon02(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon03(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon04(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div data-tippy-content="Add your text" class="tabs active"><img src="frontend/images/Icon05(Filled).svg" alt="" class="slider-tab-icon" /></div>
                               <div class="line-break"></div>
                               <div class="active-tab">
                                   <div class="slide-tab-current"><img src="frontend/images/Icon06(White).svg" alt="" class="slider-tab-icon" /></div>
                                   <div class="step-detail">
                                       <div class="step-count">Step 6/6</div>
                                       <div class="step-title">Confirmation</div>
                                   </div>
                               </div>
                           </div>
                           <div class="form-wrap">
                               <div class="title-wrap">
                                   <h2 class="form-heading">Confirm Submisson</h2>
                                   <p class="paragraph-header">Thanks for taking the time to complete this form.</p>
                               </div>
                               <div class="w-layout-grid grid-single-column">
                                   <div class="field-wrap"><label for="Email" class="field-label">What’s your best contact email?</label><input type="email" class="text-field w-input" maxlength="256" name="Email" data-name="Email" placeholder="Email" id="Email" /></div>
                               </div>
                           </div>
                           <div class="button-base">
                               <a href="#stap5" class="back-button-slide w-inline-block">
                                   <div>Back</div>
                               </a><input type="submit" value="Submit" data-wait="Please wait..." class="submit-button-grace w-button" /></div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <div class="previous-placeholder w-slider-arrow-left"><img src="frontend/images/arrow.svg" alt="" class="arrow" />
           <div class="previous-button">Previous</div>
       </div>
       <div class="next-placeholder w-slider-arrow-right">
           <div class="next-button">Next step</div>
       </div>
     </div>
   </form>
 </div>
 </template>
 
 <script >
  import axios from 'axios'

    export default{
    data(){
        return{
            materiels:[]
        }
    },
    created() {
        axios.get('https://admin.phonixphone.fr/api/materiels')
            .then(response => {
                this.materiels = response.data.materiels;
                console.log(response.data.materiels);

            });
    }
    }
 </script>
 
 <!-- Add "scoped" attribute to limit CSS to this component only -->
 <style scoped lang="scss">
 
 </style>
 