<template>
    <div class="vertical-menu">
   
   <div data-simplebar class="h-100">
   
       <!--- Sidemenu -->
       <div id="sidebar-menu">
           <!-- Left Menu Start -->
           <ul class="metismenu list-unstyled" id="side-menu">
            <li class="menu-title" key="t-menu">Menu</li>

            <li>
                <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="bx bx-home-circle"></i>
                    <span key="t-dashboards">Dashboards</span>
                </a>
            </li>
            <li>
                <a href="/boutiques" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Boutiques</span>
                </a>
            </li>
            <li>
                <a href="/materiels" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Materiels</span>
                </a>
            </li>
            <li>
                <a href="/marques" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Marques</span>
                </a>
            </li>
            <li>
                <a href="/modeles" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Modeles</span>
                </a>
            </li>
            <li>
                <a href="/pannes" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Pannes</span>
                </a>
            </li>
        </ul>
       </div>
       <!-- Sidebar -->
   </div>
   </div>
   <!-- Left Sidebar End -->
   
   
   
   <!-- ============================================================== -->
   <!-- Start right Content here -->
   <!-- ============================================================== -->
   <div class="main-content">
   
   <div class="page-content">
       <div class="container-fluid">
   
   <!-- start page title -->
   <div class="row">
       <div class="col-12">
           <div class="page-title-box d-sm-flex align-items-center justify-content-between">
               <h4 class="mb-sm-0 font-size-18">Liste des modeles</h4>
   
               <div class="page-title-right">
                   <ol class="breadcrumb m-0">
                    <a href="/modele/create" class="btn btn-primary"><i class="bx bx-plus align-middle"></i>Nouveau Modele</a>
                   </ol>
               </div>
   
           </div>
       </div>
   </div>
   <!-- end page title -->
   
   <div class="row">
       <div class="col-12">
           <div class="card">
               <div class="card-body">
                   <div class="table-rep-plugin">
                       <div class="table-responsive mb-0" data-pattern="priority-columns">
                           <table id="tech-companies-1" class="table table-striped">
                               <thead>
                               <tr>
                                <th data-priority="1">Nom du modèle</th>
                                <th data-priority="3">Nom du Marque</th>
                                  
                                   <th data-priority="3">Image</th>
                                  
                                   <th data-priority="3">Action</th>

                                
                               </tr>
                               </thead>
                               <tbody>
                               <tr v-for="modele in modeles" :key="modele.id">
                                <td>{{modele.name}}</td>
                                <td>{{modele.marque.name}}</td>
                                  
                                   <td><img :src="`${urlSrc}modeles/${modele.image}`"  class="profile-user-img img-fluid img-circle" style="height:50px; width:50px;"></td>
                                   
                
                                   <td> 
                                    <router-link :to="{name: 'editModele', params: { id: modele.id }}" ><a class="btn btn-outline-secondary btn-sm edit" title="Edit"><i class="fas fa-pencil-alt"></i></a></router-link>
                                    <button class="btn btn-outline-secondary btn-sm edit"  @click="deleteModele(modele.id)"><i class="fas fa-trash-alt"></i></button>
                                    </td>
                               </tr>
                              
                               </tbody>
                           </table>
                       </div>
   
                   </div>
   
               </div>
           </div>
       </div> <!-- end col -->
   </div> <!-- end row -->
   
   </div>
   </div>
   <!-- End Page-content -->
   
   
   <footer class="footer">
       <div class="container-fluid">
           <div class="row">
              
               <div class="col-sm-6">
                   <div class="text-sm-end d-none d-sm-block">
                       Design & Develop by Themesbrand
                   </div>
               </div>
           </div>
       </div>
   </footer>
   </div>
   <!-- end main content-->
   
   <!-- END layout-wrapper -->
   
   <!-- Right Sidebar -->
   <div class="right-bar">
   <div data-simplebar class="h-100">
   <div class="rightbar-title d-flex align-items-center px-3 py-4">
   
       <h5 class="m-0 me-2">Settings</h5>
   
       <a href="javascript:void(0);" class="right-bar-toggle ms-auto">
           <i class="mdi mdi-close noti-icon"></i>
       </a>
   </div>
   
   <!-- Settings -->
   <hr class="mt-0" />
   <h6 class="text-center mb-0">Choose Layouts</h6>
   
   <div class="p-4">
       <div class="mb-2">
           <img src="frontend/assets/images/layouts/layout-1.jpg" class="img-thumbnail" alt="layout images">
       </div>
   
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="light-mode-switch" checked>
           <label class="form-check-label" for="light-mode-switch">Light Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="frontend/assets/images/layouts/layout-2.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="dark-mode-switch">
           <label class="form-check-label" for="dark-mode-switch">Dark Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="frontend/assets/images/layouts/layout-3.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch">
           <label class="form-check-label" for="rtl-mode-switch">RTL Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="frontend/assets/images/layouts/layout-4.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-5">
           <input class="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch">
           <label class="form-check-label" for="dark-rtl-mode-switch">Dark RTL Mode</label>
       </div>
   
   
   </div>
   
   </div> <!-- end slimscroll-menu-->
   </div>
   </template>
   <script>
   import axios from 'axios'
   import defaultUrl from '../../../global.js'

 export default{
    data(){
        return{
            modeles:[],
            urlSrc:''
        }
    },
    created() {
        let token =  window.localStorage.getItem('token');
        this.urlSrc =defaultUrl;
        axios.get(defaultUrl+'api/modeles', {
            headers: {
              Authorization: "Bearer" + token,
              "x-access-token": token
            }
          })
            .then(response => {
                this.modeles = response.data.modeles;

            });
    },
    methods: {
        deleteModele(id) { 
            if (confirm("voulez vous supprimer ce modele")) {
                axios.delete(`https://admin.phonixphone.fr/api/modeles/${id}`)
                    .then(response => {
                        let i = this.modeles.map(data => data.id).indexOf(id);
                        this.modeles.splice(i, 1)
                    });
                }
               
            }
    }
}
   </script>
   <style>
   
   </style>