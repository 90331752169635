<template>
    <div class="vertical-menu">
   
   <div data-simplebar class="h-100">
       <!--- Sidemenu -->
       <div id="sidebar-menu">
           <!-- Left Menu Start -->
           <ul class="metismenu list-unstyled" id="side-menu">
            <li class="menu-title" key="t-menu">Menu</li>

            <li>
                <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="bx bx-home-circle"></i>
                    <span key="t-dashboards">Dashboards</span>
                </a>
            </li>
            <li>
                <a href="/boutiques" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Boutiques</span>
                </a>
            </li>
            <li>
                <a href="/materiels" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Materiels</span>
                </a>
            </li>
            <li>
                <a href="/marques" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Marques</span>
                </a>
            </li>
            <li>
                <a href="/modeles" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Modeles</span>
                </a>
            </li>
            <li>
                <a href="/materiels" class="has-arrow waves-effect">
                    <i class="bx bx-list-ul"></i>
                    <span key="t-tables">Pannes</span>
                </a>
            </li>
        </ul>
       </div>
       <!-- Sidebar -->
   </div>
   </div>
   <!-- Left Sidebar End -->
   
   
   
   <!-- ============================================================== -->
   <!-- Start right Content here -->
   <!-- ============================================================== -->
   <div class="main-content">
   
   <div class="page-content">
        <div class="col-12">
            <div class="card">
                <form @submit.prevent="updateBoutique">
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Nom de utilisateur</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Entrez nom utilisateur" v-model="boutique.user.name">
                    </div>
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Email</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Entrez email" v-model="boutique.user.email">
                    </div>
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Nom de Boutique</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Entrez nom" v-model="boutique.nom">
                    </div>
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Télephone de Boutique</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Entrez téléphone" v-model="boutique.telephone">
                    </div>
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">SIRET</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Entrez siret" v-model="boutique.siret">
                    </div>
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Adresse</label>
                        <input type="text" class="form-control" id="formrow-firstname-input" placeholder="Entrez adresse" v-model="boutique.adresse">
                    </div>
                    <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Statut</label>
                        <select  class="form-control"  v-model="boutique.statut">
                        <option value="1">Active</option>
                        <option value="0">Disactive</option>
                        </select>
                    </div>
                  
                    <button type="submit" class="btn btn-primary w-md">Submit</button>
                </form>
            </div>
        </div> <!-- end col -->
</div>
   <!-- End Page-content -->
   
   
   <footer class="footer">
       <div class="container-fluid">
           <div class="row">
              
               <div class="col-sm-6">
                   <div class="text-sm-end d-none d-sm-block">
                       Design & Develop by Themesbrand
                   </div>
               </div>
           </div>
       </div>
   </footer>
   </div>
   <!-- end main content-->
   
   <!-- END layout-wrapper -->
   
   <!-- Right Sidebar -->
   <div class="right-bar">
   <div data-simplebar class="h-100">
   <div class="rightbar-title d-flex align-items-center px-3 py-4">
   
       <h5 class="m-0 me-2">Settings</h5>
   
       <a href="javascript:void(0);" class="right-bar-toggle ms-auto">
           <i class="mdi mdi-close noti-icon"></i>
       </a>
   </div>
   
   <!-- Settings -->
   <hr class="mt-0" />
   <h6 class="text-center mb-0">Choose Layouts</h6>
   
   <div class="p-4">
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-1.jpg" class="img-thumbnail" alt="layout images">
       </div>
   
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="light-mode-switch" checked>
           <label class="form-check-label" for="light-mode-switch">Light Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-2.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="dark-mode-switch">
           <label class="form-check-label" for="dark-mode-switch">Dark Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-3.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-3">
           <input class="form-check-input theme-choice" type="checkbox" id="rtl-mode-switch">
           <label class="form-check-label" for="rtl-mode-switch">RTL Mode</label>
       </div>
   
       <div class="mb-2">
           <img src="/frontend/assets/images/layouts/layout-4.jpg" class="img-thumbnail" alt="layout images">
       </div>
       <div class="form-check form-switch mb-5">
           <input class="form-check-input theme-choice" type="checkbox" id="dark-rtl-mode-switch">
           <label class="form-check-label" for="dark-rtl-mode-switch">Dark RTL Mode</label>
       </div>
   
   
   </div>
   
   </div> <!-- end slimscroll-menu-->
   </div>
   </template>
   <script>
   import axios from 'axios'

 export default{
    data(){
        return{           
          boutique: {}
            
        }
    },
    created() {
            axios
                .get(`http://admin.phonixphone.fr/api/boutiques/${this.$route.params.id}`)
                .then((res) => {
                    this.boutique = res.data.boutique;
                });
    },
        methods: {
          
            updateBoutique() {
                axios
                    .put(`http://admin.phonixphone.fr/api/boutiques/${this.$route.params.id}`, this.boutique)
                    .then((res) => {
                        this.$router.push({ name: 'boutiques' });
                    });
            }
        }

        
}
   </script>
   <style>
   
   </style>